import React from "react"
import Layout from '../../components/layout'; 
import DropdownMenu from '../../components/dropdownMenu'
import productStyles from '../../styles/product.module.scss'
import image from '../../images/vector-image-3.jpg';
import dropdownStyles from '../../styles/dropdown.module.scss'

const VisitasPage = () => {
    return(
        <Layout>
            <DropdownMenu/>
            <div className={productStyles.descripcion}>
                <h3>Visitas Domiciliarias</h3>
                <p className={productStyles.texto}>Verificación de domicilios comprobando la existencia del domicilio proporciomado por el cliente, con el fin de integrar expedientes de KYC (Conoce a tu cliente, por sus siglas en inglés) al momento del inicio de la solicitud de una apertura de su cuenta, contrato, servicios, etc.</p>
                <img className={dropdownStyles.image} src={image} alt="imagen productos" /> 
            </div>
        </Layout>
    ) 
}
export default VisitasPage;